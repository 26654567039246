import { Component, HostBinding, Input } from '@angular/core';

enum ECloseColor {
  BLACK = 'black',
  GRAY_9 = 'gray-9',
  WHITE = 'white'
}

// TODO: replace with IconComponent
@Component({
    selector: 'app-close-icon ',
    templateUrl: './close-icon.component.html',
    styleUrls: [
        './close-icon.component.scss',
    ]
})
export class CloseIconComponent {
  @Input()
  @HostBinding('class')
  public color: ECloseColor = ECloseColor.BLACK;
}