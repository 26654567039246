import { DOCUMENT } from "@angular/common";
import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, OnDestroy, Output } from "@angular/core";
import { Subscription, filter, fromEvent } from "rxjs";

/* eslint-disable @angular-eslint/directive-selector */

@Directive({ selector: '[clickOrKeyupOutside]' })
export class ClickOrKeyupOutsideDirective implements AfterViewInit, OnDestroy{
    @Output() clickOutsideEmitter = new EventEmitter<void>();
    @Output() KeyupOutsideEmitter = new EventEmitter<void>();

    documenClickSubscription: Subscription | undefined;
    documenKeyupSubscription: Subscription | undefined;

    constructor(private element: ElementRef, @Inject(DOCUMENT) private document: Document) {}

    ngAfterViewInit(): void {
        this.documenClickSubscription = fromEvent(this.document, 'click')
        .pipe(
            filter(event => {
                return !this.isInside(event.target as HTMLElement);
            })
        ).subscribe(() => {
            this.clickOutsideEmitter.emit();
        })

        this.documenKeyupSubscription = fromEvent(this.document, 'keyup')
        .pipe(
            filter(event => {
                return !this.isInside(event.target as HTMLElement);
            })
        ).subscribe(() => {
            this.KeyupOutsideEmitter.emit();
        })
    }

    ngOnDestroy(): void {
        this.documenClickSubscription?.unsubscribe();
        this.documenKeyupSubscription?.unsubscribe();
    }

    isInside(elementToCheck: HTMLElement): boolean {
        return elementToCheck === this.element.nativeElement || this.element.nativeElement.contains(elementToCheck);
    }
}