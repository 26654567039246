import { provideAppInitializer, inject, importProvidersFrom } from '@angular/core';
import { RoleService } from './app/core/services/roles.service';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AuthInterceptor } from './app/core/interceptors/auth/auth.interceptor';
import { LoadingInterceptor } from './app/core/services/interceptors/loading/loading-interceptor.service';
import { DialogConfig } from './app/shared/components/dialog/dialog-config';
import { DialogRef } from './app/shared/components/dialog/dialog-ref';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { InjectorContainerModule } from './app/core/injector-container/injector-container.module';
import { AppComponent } from './app/app.component';
import { provideRouter, withHashLocation } from '@angular/router';
import { routes } from './app/app-routes';
import { DatePipe } from '@angular/common';
import { provideImpersonate } from '@sl/sl-angular-library';
import { UserService } from './app/core/services/user.service';
import { ERole } from './app/core/enums/roles.enum';

function initializeApp(roleService: RoleService) {
  return () => roleService.loadRoles();
}

const impersonate_config =  {
  userService: UserService,
  historyKey:'shepherdsImpersonateHistory',
  switchUserRole:ERole.SHEPHERDS_SWITCH_USER as string
};

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(BrowserModule, InjectorContainerModule),
        provideAppInitializer(() => {
            const initializerFn = (initializeApp)(inject(RoleService));
            return initializerFn();
        }),
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        { provide: DialogConfig, useClass: DialogConfig },
        { provide: DialogRef, useClass: DialogRef },
        { provide: DatePipe, useClass: DatePipe },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations(),
        provideRouter(routes, withHashLocation()),
        provideImpersonate(impersonate_config)
    ]
})
  .catch(err => console.error(err));
