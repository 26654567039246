import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { distinctUntilChanged, debounce } from 'rxjs/operators';
import { interval, of } from 'rxjs';
import { LoadingService } from 'src/app/core/services/loading.service';
import { NgIf } from '@angular/common';
import { LuIconComponent } from '../lu-icon/lu-icon.component';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    animations: [
        trigger('enterAnimation', [
            transition(':enter', [
                style({ opacity: 1 })
            ]),
            transition(':leave', [
                animate('0.9s ease', style({ opacity: 0 }))
            ])
        ])
    ],
    imports: [NgIf, LuIconComponent]
})
export class LoadingComponent implements OnInit {

  public loading: boolean;

  constructor(private ref: ChangeDetectorRef, private loadingService: LoadingService) { }

  ngOnInit() {
    this.loadingService.pending$
      .pipe(
        distinctUntilChanged(),
        debounce(val => val ? interval(100) : of(undefined)),
      )
      .subscribe(data => {
        this.loading = data;
        /* Unfortunately, this is considerd "not best" practice. However, it is a work around for how components and interceptors don't play nice with change detection */
        this.ref.detectChanges();
      });
  }

}
