import { Routes } from '@angular/router';
import { adminRedirectGuard } from './core/guards/admin-redirect.guard';
import { studentRedirectGuard } from './core/guards/student-redirect.guard';
import { StudentLandingPageComponent } from './features/landing-page/student-landing-page.component';

export const routes: Routes = [
  {
    path: '',
    component: StudentLandingPageComponent,
    canActivate: [adminRedirectGuard]
  },
  {
    path: 'dashboard/admin',
    loadChildren: () => import('./features/admin-dashboard/admin-dashboard.routes').then(m => m.routes),
    canActivate: [studentRedirectGuard],
  },
  {
    path: 'dashboard/rs',
    loadChildren: () => import('./features/rs-dashboard/rs-dashboard.routes').then(m => m.routes),
  },
  {
    path: 'dashboard/cgl-search',
    loadChildren: () => import('./features/future-rs-dashboard/future-rs-dashboard.routes').then(m => m.routes),
  },
  {
    path: 'styleguide',
    loadComponent: () => import('./shared/components/styleguide/styleguide.component').then(c => c.StyleguideComponent)
  },
  {
    path: 'apply/:applicationType/current',
    loadChildren: () => import('./features/application/application.routes').then(m => m.routes),
  },
  {
    path: 'apply/:applicationType',
    loadChildren: () => import('./features/application/application.routes').then(m => m.routes),
  },
  {
    path: 'profile',
    loadChildren: () => import('./features/profile/profile.routes').then(m => m.routes),
  },
  {
    path: 'thank-you/:applicationType',
    loadChildren: () => import('./features/thank-you/thank-you.routes').then(m => m.routes),
  }
];

