import { Component, OnInit } from '@angular/core';
import { ERole } from './core/enums/roles.enum';
import { RoleService } from './core/services/roles.service';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { AdminHeaderComponent } from './features/admin-dashboard/admin-header/admin-header.component';
import { RouterOutlet } from '@angular/router';
import { SlImpersonateComponent } from '@sl/sl-angular-library';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [LoadingComponent, NgIf, HeaderComponent, AdminHeaderComponent, RouterOutlet, SlImpersonateComponent]
})
export class AppComponent implements OnInit{

  public isAdmin:boolean;

  constructor(private roleService: RoleService){}

  ngOnInit(): void {
    this.isAdmin = this.roleService.hasAnyRole([ERole.SHEPHERDS_ADMIN, ERole.STAFF_RSCGL, ERole.STAFF_AS, ERole.STAFF_CS]);
  }
}