import { Component, Input } from '@angular/core';
import { IMenuOption } from '../../models/menu-option.model';
import { ClickOrKeyupOutsideDirective } from '../../directives/click-outside.directive';
import { LuIconComponent } from '../lu-icon/lu-icon.component';
import { NgFor, NgIf } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-menu-dropdown',
    templateUrl: './menu-dropdown.component.html',
    styleUrls: ['./menu-dropdown.component.scss'],
    imports: [ClickOrKeyupOutsideDirective, LuIconComponent, NgFor, NgIf, RouterLink]
})
export class MenuDropdownComponent {

    @Input()
    public username: string | undefined;

    @Input()
    public menuOptions: Array<IMenuOption> = [];

    protected showMenu = false;

    toggleDropdown() {
        this.showMenu = !this.showMenu;
    }

    clickedOutside(): void {
        this.showMenu = false;
    }

    tabbedOutside(): void {
        this.showMenu = false;
    }
}
