import { Component, EventEmitter, Output } from '@angular/core';
import { CloseIconComponent } from '../close-icon/close-icon.component';
import { LuIconComponent } from '../lu-icon/lu-icon.component';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    imports: [CloseIconComponent, LuIconComponent]
})
export class NotificationComponent {

  @Output() dismissNotification: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() followRoute: EventEmitter<boolean> = new EventEmitter<boolean>();

  dismiss(): void {
    this.dismissNotification.emit(true);
  }

  follow(): void {
    this.followRoute.emit(true);
  }   
}
